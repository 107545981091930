import { STORE_TYPE, TILE_STATES } from '../components/cap/constants';
import { getInventoryByType } from '../components/cap/utils/atputils';
import { generateSKU } from '../components/global/utils/commonUtils';
import { logError } from '../components/global/utils/logger';
import { STORAGE_CONFIG } from '../constants/storageConfig';

/**
 * the hook encompasses the logic related to sources pcs shown in CAP header
 */
export const useChronosAvailability = () => {
    /**
     * This function takes 1 argument - best match store.
     * It returns a map containing status of availability of items based on availabileQuantity.
     * @param {*} bestMatchStore
     * @returns
     */
    const checkAvailabilityBestMatchStore = (bestMatchStore = {}) => {
        try {
            let availabilityInChronos = {};
            bestMatchStore?.items?.map(item => {
                if (bestMatchStore?.fulfillmentPercent > 0) {
                    if (item?.availableQuantity > 0) {
                        availabilityInChronos[generateSKU(item?.equipmentCategory, item?.equipmentClass)] =
                            TILE_STATES.AVAILABLE;
                    } else {
                        availabilityInChronos[generateSKU(item?.equipmentCategory, item?.equipmentClass)] =
                            TILE_STATES.CHANGE_DATES_GRT24HRS;
                    }
                } else {
                    availabilityInChronos[generateSKU(item?.equipmentCategory, item?.equipmentClass)] =
                        TILE_STATES.CHANGE_DATES_GRT72HRS;
                }
            });
            return availabilityInChronos;
        } catch (err) {
            logError(err, false, 'useChronosAvailability');
            return {};
        }
    };

    /**
     * This function takes two arguments - an array of location stores and an array of store data from a source.
     * It returns a sorted array of stores based on the fulfillment percent.
     * @param {*} locationStores
     * @param {*} chronosStores
     * @returns
     */
    const getMergedSourcesATPStores = (locationStores = [], chronosStores = []) => {
        try {
            let sortedStores = [];
            // Create a map to efficiently lookup fulfillment values by pc
            const fulfillmentPercentMap = new Map(
                chronosStores?.map(item => [String(item.pc), item.fulfillmentPercent])
            );
            function sortByFulfilment(a, b) {
                // Get percent values for the corresponding branchNumber i.e pc in array2
                const percentA = fulfillmentPercentMap.get(a?.pc) || 0; // Default to 0 if id not found
                const percentB = fulfillmentPercentMap.get(b?.pc) || 0; // Default to 0 if id not found
                // Sort by percent values in descending order
                return percentB - percentA;
            }
            // Custom sorting function
            // Sort array using the custom sorting function
            sortedStores = locationStores?.slice().sort(sortByFulfilment);
            return sortedStores;
        } catch (e) {
            logError(e, false, 'getMergedSourcesATPStores');
            return [];
        }
    };
    /**
     * This function takes an array of store data as an argument.
     * It filters out the products that have an available quantity greater than 0 and returns an array of these available products.
     * @param {} chronosStores
     * @param {} selectedStoreDetails
     * @param {} selectedStoreAvailability if true decides availabilty from selected store else from chronos first store
     * @returns
     */
    const getAvailableProducts = (chronosStores = [], selectedStoreDetails = {}) => {
        try {
            let availableProducts = [];
            let unavailable48Hrs = [];
            let unavailable72Hrs = [];
            const storeToCheckAvailableItems = selectedStoreDetails?.pc ? selectedStoreDetails : chronosStores?.[0];

            availableProducts = storeToCheckAvailableItems?.items
                ?.filter(item => item?.availableQuantity > 0)
                ?.map(item => ({
                    ...item,
                    catclass: generateSKU(item.equipmentCategory, item.equipmentClass)
                }));

            // Create an object for available product catclasses for quick lookup
            const availableProductClasses = {};
            availableProducts?.forEach(item => {
                availableProductClasses[item.catclass] = true;
            });

            // Initialize an object to store the cumulative availability data for items across stores
            const itemAvailabilityObj = {};

            for (let i = 1; i < chronosStores.length; i++) {
                chronosStores?.[i]?.items.forEach(item => {
                    //available in other stores then first store and not already existing in unavailable48 items
                    const itemCatClass = generateSKU(item.equipmentCategory, item.equipmentClass);

                    if (availableProductClasses[itemCatClass]) {
                        return; // Skip items available in the first store
                    }

                    if (!itemAvailabilityObj[itemCatClass]) {
                        itemAvailabilityObj[itemCatClass] = {
                            availableQuantity: 0,
                            itemDetails: { ...item, catclass: itemCatClass }
                        };
                    }
                    if (item?.availableQuantity > 0) {
                        itemAvailabilityObj[itemCatClass].availableQuantity += item?.availableQuantity;
                    }
                });
            }

            for (const itemCatClassKey in itemAvailabilityObj) {
                const { availableQuantity, itemDetails } = itemAvailabilityObj[itemCatClassKey];

                // not available in any store nor in available store
                if (availableQuantity === 0) {
                    unavailable72Hrs.push(itemDetails);
                } else if (availableQuantity > 0) {
                    unavailable48Hrs.push(itemDetails);
                }
            }
            // conditionally availble
            return { availableProducts, unavailable48Hrs, unavailable72Hrs };
        } catch (e) {
            logError(e, false, 'getAvailableProducts', chronosStores);
            return [];
        }
    };

    /**
     * This function takes an array of store data as an argument.
     * It returns stores and products that have an available quantity euql to 0
     * @param {} chronosStores
     * @returns
     */
    const getStoresWithUnvailableProducts = (chronosStores = []) => {
        try {
            const storesWithUnavailableProductsMap = [];

            for (let i = 0; i < chronosStores.length; i++) {
                let unavailableProducts = [];
                chronosStores?.[i]?.items.forEach(item => {
                    //available in other stores then first store and not already existing in unavailable48 items
                    const itemCatClass = generateSKU(item.equipmentCategory, item.equipmentClass);
                    if (item?.availableQuantity <= 0) {
                        unavailableProducts.push({ ...item, catclass: itemCatClass });
                    }
                });
                storesWithUnavailableProductsMap.push({ store: chronosStores?.[i], unavailableProducts });
            }
            // conditionally availble
            return storesWithUnavailableProductsMap;
        } catch (e) {
            logError(e, false, 'getStoresWithUnvailableProducts', chronosStores);
            return [];
        }
    };
    /**
     * This function takes three arguments - an array of stores, an array of cart items and a type.
     * It determines the availability of cart items in the provided stores and returns an array of stores
     * with additional properties availableItems and unavailableItems.
     * @param {*} stores
     * @param {*} cartItems
     * @param {*} type
     * @returns
     */
    const determineCartStoreAvailaility = (stores = [], cartItems = [], type = STORE_TYPE.INVENTORY) => {
        try {
            const companyId = localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.COMPANYID) || '01';
            let storesWithCartAvail = [];
            let availableItems = [];
            let unavailableItems = [];
            for (let i = 0; i < stores.length; i++) {
                for (let j = 0; j < cartItems.length; j++) {
                    const inventoryDataObj = JSON.parse(cartItems[j]?.product?.ec_pc_inventory || '{}');
                    const currPc = `${companyId}_${stores[i]?.pc}`;
                    const inventoryPipeSeperatedVal =
                        inventoryDataObj?.[currPc] && inventoryDataObj?.[currPc]?.split('|');
                    if (inventoryPipeSeperatedVal && inventoryPipeSeperatedVal?.length > 0) {
                        const typeInventoryQty = getInventoryByType(inventoryPipeSeperatedVal, type);
                        if (typeInventoryQty !== 0) {
                            const { sku } = cartItems[j];
                            availableItems.push(sku);
                        } else {
                            unavailableItems.push(sku);
                        }
                    }
                }
                const storesObj = {
                    ...stores[i],
                    availableItems,
                    unavailableItems
                };
                storesWithCartAvail.push([storesObj]);
            }
        } catch (e) {
            logError(e, false, 'determineStoreAvailaility');
            return stores;
        }
    };

    const getItemAvailabilityATPChronos = (items = [], atpArr = [], chronosArr = []) => {
        let availableItems = [];
        let conditionalItems = [];
        let unavailableItems = [];
        items?.forEach(item => {
            if (atpArr?.availableItems?.find(atpItem => atpItem?.product?.sku === item?.product?.sku)) {
                if (chronosArr?.availableProducts?.find(chronosItem => chronosItem?.catclass === item?.product?.sku)) {
                    availableItems.push(item);
                } else {
                    conditionalItems?.push({
                        ...item,
                        status: TILE_STATES.CHANGE_DATES_GRT72HRS,
                        prices: { row_total: { currency: '', value: 0 } }
                    });
                }
            } else if (atpArr?.unavailableItems?.find(atpItem => atpItem?.product?.sku === item?.product?.sku)) {
                unavailableItems?.push(item);
            } else {
                const matchedConditionalItem = atpArr?.conditionalItems?.find(
                    atpItem => atpItem?.product?.sku === item?.product?.sku
                );
                conditionalItems?.push(matchedConditionalItem);
            }
        });
        return { availableItems, conditionalItems, unavailableItems };
    };

    return {
        getMergedSourcesATPStores,
        getAvailableProducts,
        determineCartStoreAvailaility,
        checkAvailabilityBestMatchStore,
        getItemAvailabilityATPChronos,
        getStoresWithUnvailableProducts
    };
};
