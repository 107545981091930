import React, { memo } from 'react';
import { string } from 'prop-types';
import AlertIcon from '../../../../resources/images/alert-triangle-red.svg';
import './errorinput.scss';

const ErrorInput = props => {
    const { errorMsg, customClassName } = props;

    return (
        <>
            {errorMsg ? (
                <div
                    className={`${customClassName} error_input`}
                    tabIndex="0"
                    aria-live="assertive"
                    role="alert"
                    data-testid="error-input-testid">
                    <AlertIcon tabIndex="-1" aria-hidden={true} />
                    <span className="error_input__span">{errorMsg}</span>
                </div>
            ) : (
                ''
            )}
        </>
    );
};

ErrorInput.propTypes = {
    errorMsg: string,
    customClassName: string
};

ErrorInput.defaultProps = {
    errorMsg: '',
    customClassName: ''
};
export default memo(ErrorInput);
