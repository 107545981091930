export const CREATE_ACCOUNT = 'create-account';
export const EMAIL_VERIFY = 'email-verify';
export const LOOKS_LIKE = 'convertProfile';
export const LOGIN = 'login';
export const PERSONAL_ACCOUNT = 'personal';
export const BUSINESS_ACCOUNT = 'business';
export const CREDIT = 'CREDIT';
export const NON_CREDIT = 'NON_CREDIT';
export const ACCOUNT_TYPE = 'accountType';
export const ACCOUNT = 'account';
export const CONTINUE = 'continue';
export const EMAIL = 'email';
export const USER_EXISTS = 'userExists';
export const LOGIN_SCREEN = window?.location?.protocol + '//' + window?.location?.host + '/login/';
export const AEM_CONTAINS_PHONE_NUMBER = '#phonenumber';
export const AEM_CONTAINS_EMAIL = '#mail';
export const VERIFY_CODE = 'verifyCode';
export const LOOK_LIKE_ERR_MESSAGE = 'Please indicate whether or not you understand.';
export const API_STATUS = {
    APPROVED: 'approved',
    PENDING: 'pending'
};
export const SUCCESS_CODES = {
    SUCCESS_CODE_201: 201,
    SUCCESS_CODE_200: 200
};
export const ERROR_CODES = {
    NOT_FOUND: 404,
    TOO_MANY_REQUESTS: 429
};

export const TWILIO_CODE_LIMIT = 6;
export const DL_EXIST_ERROR_CODE = '422-002';
export const CASH_FLOW_PATH = '/createaccount/personal';
export const CREATE_ACCOUNT_PATH = '/createaccount/customer';
export const THANK_YOU_PATH = '/createaccount/personal/thank-you';
const currentLocationPath = window?.location?.protocol + '//' + window?.location?.host;
export const CREATE_ACCOUNT_SCREEN = currentLocationPath + CREATE_ACCOUNT_PATH;

const isDevelopment = process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test';
export const ERROR_SCREEN = `${currentLocationPath}/${
    isDevelopment
        ? 'content/sunbeltrentals/language-masters/en/createaccount/customer/application-error.html?wcmmode=disabled'
        : 'createaccount/customer/application-error/'
}`;

export const CCA_PATH = `${currentLocationPath}/${
    isDevelopment
        ? 'content/sunbeltrentals/language-masters/en/cca.html/personal-information'
        : 'cca/personal-information'
}`;
