import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { useIntl } from 'react-intl';
import { useFilterState } from '../../../cap';
import { useCartState } from '../../../../contexts/cart/cartContext';
import useUserData from '../../../../hooks/useUserData';
import SideDrawerModal from '../modals/SideDrawerModal';
import Button from '../../atoms/button/button';
import { Tab, Tabs } from '../../atoms/tabs';
import { updateDefaultAccount } from '../../api/CommonResponseHandler';
import CloseIcon from '../../../../resources/images/close.svg';
import AlertIconRed from '../../../../resources/images/alert-circle-red.svg';
import './ChooseAccountDrawer.scss';
import { STORAGE_CONFIG } from '../../../../constants/storageConfig';
import StarSolidIcon from '../../../../../main/resources/images/star-solid.svg';
import StarIcon from '../../../../../main/resources/images/star.svg';
import {
    RESET_GEOGRAPHY,
    RESET_LOCATION_DATA,
    RESET_PROJECT,
    SET_ACCOUNT_FAVORITE_LIST_DATA,
    SET_ALL_ACCOUNT_PROJECTS,
    SET_CAP_ACCOUNT_SELECTED,
    SET_CREDIT_NEWADDR_FLAG,
    SET_IS_FAVORITES_LOADING,
    SET_LAST_USED_JOBSITE,
    SET_LOCATION_LOADING,
    SET_SHOW_CHOOSE_ACCOUNT_DRAWER,
    SET_SHOW_DELINQUENT_ACCOUNT_MODAL,
    SET_CART_ITEM_STATUS_CHANGED_REASON,
    UPDATE_ACCOUNT
} from '../../../../aem-core-components/actions/constants';
import { useUserContext } from '../../../../aem-core-components/context/UserContext';
import { useCheckAuthorityType } from '../../../../hooks/useCheckUser';
import { MIN_SEARCH_QUERY_LENGTH, isAccountBlocked, isAccountClosed, isAccountNotActive } from '../../utils/commonUtils';
import { UPDATE_ACCOUNT_FAVORITE_LIST } from '../../../../aem-core-components/queries/mutation_update_account_favorite';
import { logError } from '../../utils/logger';
import { AUTHORITY_TYPE } from '../../constants';
import { SET_IS_JOBSITE_RECOMMENDATION_LOADING, SET_SELECTED_STORE_DETAILS } from '../../../cap/constants';
import './ChooseAccountDrawer.scss';
import AllAccounts from './AllAccounts';
import FavoriteAccounts from './FavoriteAccounts';
import './ChooseAccountDrawer.scss';
import { chooseAccountDrawerDataLocators } from './dataLocators';
import { VARIABLE_CONFIG } from '../../../../constants/analyticsConstants/Variables';

const ChooseAccountDrawer = () => {
    const intl = useIntl();
    const [updateAccountFavoriteList] = useMutation(UPDATE_ACCOUNT_FAVORITE_LIST);
    const [userState, { dispatch: userDispatch }] = useUserContext();
    const [, filterDispatch] = useFilterState();
    const [{ userAccount }, dispatch] = useCartState();
    const {
        userProfile,
        showChooseAccountDrawer,
        isCapAccountSelected,
        isUserProfileLoading,
        favoriteAccountListData,
        isFavoritesLoading
    } = userState;
    const authorityType = useCheckAuthorityType();
    const isP2PUser = authorityType === AUTHORITY_TYPE.P2P;
    /** For P2P user no need to select first account by default in account drawer */
    const defaultAccount = userAccount?.accountNumber || (isP2PUser ? '' : userProfile?.accounts[0]?.account);

    const [selTabIndex, setSelTabIndex] = useState(0);
    const [selectedValue, setSelectedValue] = useState(defaultAccount);
    const [accountData, setAccountData] = useState(userAccount || userProfile?.accounts[0] || {});
    const [accountList, setAccountList] = useState(userProfile?.accounts);
    const [favoriteList, setFavoriteList] = useState(favoriteAccountListData);
    const defaultCompanyId = parseInt(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.COMPANYID));
    const isCanada = defaultCompanyId !== 1;
    const [{ clearProjectDetailCookies }] = useUserData();
    const searchCriteria = ['name', 'account', 'address1', 'address2', 'state', 'city', 'zip'];

    const handleBlockedAccount = () => {
        if (isAccountBlocked(userAccount)) {
            dispatch({
                type: SET_SHOW_DELINQUENT_ACCOUNT_MODAL,
                showDelinquentModal: true
            });
            return true;
        }
        return false;
    };

    const getAccountsLengthToDisplay = () => {
        if (isP2PUser) {
            return userProfile?.accounts?.filter(account => !isAccountNotActive(account.accountStatus))?.length;
        }
        return userProfile?.accounts?.length;
    };

    const accountListLength = getAccountsLengthToDisplay();

    const sortSelectedAccounts = (selectedAccount, accountList = []) => {
        const clonedAccountList = [...accountList];

        return clonedAccountList.sort((a, b) => {
            const isSelectedA = selectedAccount === a?.account;
            const isSelectedB = selectedAccount === b?.account;

            if (isSelectedA) return -1;
            if (isSelectedB) return 1;
            return 0;
        });
    };

    const updateAccountDetailsHandler = data => {
        setSelectedValue(data?.account);
        setAccountData(data);
    };

    const updateDispatchAccount = accountData => {
        dispatch({
            type: UPDATE_ACCOUNT,
            accountName: accountData?.accountName || accountData?.name,
            accountNumber: accountData?.accountNumber || accountData?.account,
            accountStatus: accountData?.accountStatus,
            isCorpLinkAccount: accountData?.isCorpLinkAccount
        });
    };

    const userDetailHandler = async data => {
        if (!data || !data?.name) {
            if (authorityType !== AUTHORITY_TYPE.P2P) {
                updateDefaultAccount(userAccount?.accountNumber, isCanada, userAccount?.accountName);
            }
            updateDispatchAccount(userAccount);
        } else if (userAccount?.accountNumber !== data?.account) {
            userDispatch({ type: 'setHasAccountChanged', value: true });
            filterDispatch({ type: RESET_LOCATION_DATA });
            filterDispatch({ type: RESET_GEOGRAPHY });
            localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.SELECTED_ACCOUNT_ID, data?.account);
            sessionStorage.removeItem(STORAGE_CONFIG.SESSION_STORAGE.OVERRIDEPC);
            sessionStorage.removeItem(STORAGE_CONFIG.SESSION_STORAGE.BSR_PC_LIST);
            sessionStorage.removeItem(STORAGE_CONFIG.SESSION_STORAGE.IS_LOCATIONS_TIER_2_RADIUS);
            sessionStorage.removeItem(STORAGE_CONFIG.SESSION_STORAGE.LOCALWISHLISTDATA);
            if (authorityType !== AUTHORITY_TYPE.P2P) {
                updateDefaultAccount(data?.account, isCanada, data?.name);
            }
            sessionStorage.removeItem(STORAGE_CONFIG.SESSION_STORAGE.IS_ALL_JOBSITE_FETCHED);
            sessionStorage.removeItem(STORAGE_CONFIG.SESSION_STORAGE.RECOMMENDED_JOBSITE);
            filterDispatch({ type: SET_LOCATION_LOADING, isLocationsLoading: true });
            updateDispatchAccount(data);
            clearProjectDetailCookies();
            filterDispatch({ type: RESET_PROJECT });
            filterDispatch({ type: SET_IS_JOBSITE_RECOMMENDATION_LOADING, isLoading: true });
            filterDispatch({
                type: SET_LAST_USED_JOBSITE,
                lastUsedJobsite: {}
            });
            filterDispatch({ type: SET_SELECTED_STORE_DETAILS, selectedStoreDetails: {} });
            dispatch({ type: SET_CREDIT_NEWADDR_FLAG, isCreditNewAddress: false });
            localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.COMPANYID, data?.companyID);
            localStorage.removeItem(STORAGE_CONFIG.LOCAL_STORAGE.PROJECTDETAILS);
            sessionStorage.removeItem(STORAGE_CONFIG.SESSION_STORAGE.ISJOBSITESFETCHED);
            userDispatch({ type: SET_ALL_ACCOUNT_PROJECTS, allAccountProjects: [] });
            sessionStorage.removeItem(STORAGE_CONFIG.SESSION_STORAGE.AVS_RESPONSE);
            dispatch({
                type: SET_CART_ITEM_STATUS_CHANGED_REASON,
                cartItemsAvailabilityChangedReason: VARIABLE_CONFIG.CART_ITEM_STATUS_CHANGED_REASON.ACCOUNT_DETAILS_CHANGED
            });
        }

        userDispatch({ type: SET_SHOW_CHOOSE_ACCOUNT_DRAWER, payload: false });
        if (isCapAccountSelected) {
            userDispatch({ type: SET_CAP_ACCOUNT_SELECTED, payload: false });
        }
    };

    const onModalClose = () => {
        userDispatch({ type: SET_SHOW_CHOOSE_ACCOUNT_DRAWER, payload: false });
        userDispatch({ type: SET_CAP_ACCOUNT_SELECTED, payload: false });
        handleBlockedAccount();
    };

    const handleIconInactive = status => {
        if (isAccountNotActive(status)) {
            return <AlertIconRed tabIndex={'-1'} aria-hidden={true} />;
        }
    };

    const handleTextInactive = status => {
        if (isAccountNotActive(status)) {
            return intl.formatMessage({ id: 'delinquent-account-text' });
        }
        return '';
    };

    const filterData = (selectedTabRecords, selectedTabAllRecords, query = '') => {
        const loweredQuery = query?.toLowerCase();
        if (loweredQuery?.length < MIN_SEARCH_QUERY_LENGTH) {
            return selectedTabRecords;
        }
        return selectedTabAllRecords?.filter(data => {
            return Object.keys(data)?.some(key => {
                if (key === 'address') {
                    return Object.keys(data?.address)?.some(addressKey =>
                        searchCriteria?.includes(addressKey)
                            ? data?.address[addressKey]?.toString()?.toLowerCase()?.includes(loweredQuery)
                            : false
                    );
                }
                return searchCriteria?.includes(key)
                    ? data[key]?.toString()?.toLowerCase()?.includes(loweredQuery)
                    : false;
            });
        });
    };

    const accountDrawerHeading = () => {
        return (
            <>
                <h5>{intl.formatMessage({ id: 'choose-an-account' })}</h5>
                <Button
                    customButtonAriaLabel={intl.formatMessage({ id: 'common:close-dialog' })}
                    onClick={onModalClose}>
                    <CloseIcon aria-hidden={true} tabIndex={'-1'} />
                </Button>
            </>
        );
    };

    const drawerFooterLabel = selectedTabRecords => {
        return selectedTabRecords
            ? intl.formatMessage({ id: 'select-account' })
            : intl.formatMessage({ id: 'close-account' });
    };

    const getSelectedTabRecordLableInfo = () => {
        if (selectedValue) {
            if (selTabIndex === 0) {
                return accountListLength;
            } else {
                return favoriteAccountListData?.length;
            }
        }
        return 0;
    };

    const drawerFooter = () => {
        const selectedTabRecords = getSelectedTabRecordLableInfo();
        return (
            <Button
                className="modal-footer-button"
                buttonAriaLabel={drawerFooterLabel(selectedTabRecords)}
                onClick={() => (selectedTabRecords ? userDetailHandler(accountData) : onModalClose())}>
                {drawerFooterLabel(selectedTabRecords)}
            </Button>
        );
    };

    const getFormattedAddress = (address, status) => {
        const { address1, address2, city, state, zip } = address || {};
        return (
            <>
                <small className="card__content card__content-ellipsis">
                    {`${address1?.toLowerCase()} ${address2?.toLowerCase()}`}
                </small>
                <small className="card__content">
                    <span className="card__content-span">{`${city?.toLowerCase()}, ${state} ${zip}`}</span>
                    {handleIconInactive(status)}
                </small>
            </>
        );
    };
    const handleFavoriteClick = async (accountNumber, event) => {
        event.stopPropagation();
        try {
            if (isFavoritesLoading) return;
            userDispatch({ type: SET_IS_FAVORITES_LOADING, payload: true });
            const { data, error } = await updateAccountFavoriteList({
                variables: { account_id: accountNumber },
                fetchPolicy: 'network-only'
            });
            if (data?.addCustomerAccountToFavorites) {
                var accIndex = accountList.findIndex(item => item?.account === accountNumber);
                if (accountList[accIndex].isFavorite) {
                    accountList[accIndex].isFavorite = false;
                    const updatedFavoriteList = favoriteAccountListData?.filter(
                        item => item?.account !== accountNumber
                    );
                    userDispatch({ type: SET_ACCOUNT_FAVORITE_LIST_DATA, payload: updatedFavoriteList });
                    setFavoriteList(updatedFavoriteList);
                } else {
                    accountList[accIndex].isFavorite = true;
                    userDispatch({
                        type: SET_ACCOUNT_FAVORITE_LIST_DATA,
                        payload: [...favoriteAccountListData, accountList[accIndex]]
                    });
                    setFavoriteList([...favoriteAccountListData, accountList[accIndex]]);
                }
            }
            userDispatch({ type: SET_IS_FAVORITES_LOADING, payload: false });
        } catch (error) {
            userDispatch({ type: SET_IS_FAVORITES_LOADING, payload: false });
            logError(error, false, 'updateAccountFavorites');
        }
    };

    const cardStyle = data => {
        const { address } = data;
        return (
            <div className="card">
                {selectedValue === data?.account && (
                    <div className="card__tag">{intl.formatMessage({ id: 'common:selected' })}</div>
                )}
                <small className="card__title">
                    {data?.name}
                    {isP2PUser && (
                        <span
                            data-testid={`${chooseAccountDrawerDataLocators.star_icon}-${data?.account}`}
                            onClick={event => handleFavoriteClick(data?.account, event)}>
                            {!data?.isFavorite ? (
                                <StarIcon className="button_icon empty-star" aria-hidden={true} tabIndex={'-1'} />
                            ) : (
                                <StarSolidIcon className="button_icon filled-star" aria-hidden={true} tabIndex={'-1'} />
                            )}
                        </span>
                    )}
                </small>
                <small className="card__content">
                    <span>
                        {intl.formatMessage({ id: 'account-number-label' })}
                        {data?.account}
                    </span>
                </small>
                {isP2PUser ? (
                    <>
                        <small className="card__content">
                            {address?.address1} {address?.address2}
                        </small>
                        <small className="card__content">
                            {address?.city}, {address?.state} {address?.zip}
                        </small>
                    </>
                ) : (
                    getFormattedAddress(data?.address, data?.accountStatus)
                )}
            </div>
        );
    };

    const drawerContent = () => {
        if (isP2PUser) {
            return (
                <div className="choose-account-container">
                    <Tabs selTabIndex={selTabIndex} onTabChange={setSelTabIndex} tabWrapClasses="account-tabs">
                        <Tab
                            title={`${intl.formatMessage({ id: 'account-tab-label' })} (${accountListLength})`}
                            customButtonAriaLabel={`${intl.formatMessage({
                                id: 'account-tab-label'
                            })} (${accountListLength})`}>
                            <AllAccounts
                                accountList={accountList}
                                setAccountList={setAccountList}
                                selectedValue={selectedValue}
                                setSelectedValue={setSelectedValue}
                                cardStyle={cardStyle}
                                updateAccountDetailsHandler={updateAccountDetailsHandler}
                                handleTextInactive={handleTextInactive}
                                sortSelectedAccounts={sortSelectedAccounts}
                                filterData={filterData}
                                isUserProfileLoading={isUserProfileLoading}
                                userProfile={userProfile}
                            />
                        </Tab>
                        <Tab
                            title={`${intl.formatMessage({ id: 'favorite-tab-label' })} (${
                                favoriteAccountListData?.length
                            })`}
                            customButtonAriaLabel={`${intl.formatMessage({ id: 'favorite-tab-label' })} (${
                                favoriteAccountListData?.length
                            })`}>
                            <FavoriteAccounts
                                favoriteList={favoriteList}
                                setFavoriteList={setFavoriteList}
                                selectedValue={selectedValue}
                                setSelectedValue={setSelectedValue}
                                cardStyle={cardStyle}
                                updateAccountDetailsHandler={updateAccountDetailsHandler}
                                handleTextInactive={handleTextInactive}
                                sortSelectedAccounts={sortSelectedAccounts}
                                filterData={filterData}
                                isUserProfileLoading={isUserProfileLoading}
                                favoriteAccountListData={favoriteAccountListData}
                            />
                        </Tab>
                    </Tabs>
                </div>
            );
        } else {
            return (
                <AllAccounts
                    accountList={accountList}
                    setAccountList={setAccountList}
                    selectedValue={selectedValue}
                    setSelectedValue={setSelectedValue}
                    cardStyle={cardStyle}
                    updateAccountDetailsHandler={updateAccountDetailsHandler}
                    handleTextInactive={handleTextInactive}
                    sortSelectedAccounts={sortSelectedAccounts}
                    filterData={filterData}
                    isUserProfileLoading={isUserProfileLoading}
                    userProfile={userProfile}
                />
            );
        }
    };

    return (
        <SideDrawerModal
            header={accountDrawerHeading()}
            isModalOpen={showChooseAccountDrawer}
            footer={drawerFooter()}
            content={drawerContent()}
            modalContentClass={`account-drawer-content`}
            handleModalToggle={onModalClose}
            showSliderTransition={true}
        />
    );
};

export default React.memo(ChooseAccountDrawer);
