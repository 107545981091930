export const quoteListDataLocators = {
    quote_list_search_input: 'quote_list_search_input',
    quote_list_filters_CTA: 'quote_list_filters_CTA',
    quote_list_choose_column_dropdown: 'quote_list_choose_column_dropdown',
    quote_list_export_CTA: 'quote_list_export_CTA',
    quote_list_select_dropdown_testid: 'quote_list_select_dropdown_testid',
    quote_list_header_name: 'quote_list_header_name',
    quote_list_back_to_home_cta: 'quote_list_back_to_home_cta',
    quote_list_clear_filters_cta: 'quote_list_clear_filters_cta',
    quotesearch_btn_textid: 'searchquote_btn',
    quote_list_tile_mobile: 'quote_list_tile_mobile'
};

export const quoteFilterDataLocators = {
    filter_modal_cross_icon: 'filter_modal_cross_icon',
    filter_modal_close_btn: 'filter_modal_close_btn',
    accordion_test_id: 'accordion_test_id',
    accounts_filter_selection_test_id: 'accounts_filter_selection_test_id',
    filter_check_box: 'filter_check_box',
    jobsites_filter_selection_test_id: 'jobsites_filter_selection_test_id',
    filter_modal_submit_btn: 'filter_modal_submit_btn',
    quote_list_filter_search_input: 'quote_list_filter_search_input',
    orderedBy_test_id: 'orderedBy_test_id',
    fulfillment_test_id: 'fulfillment_test_id',
    filter_multiselect_title_testid: 'filter_multiselect_title_testid',
    filter_select_all_cta_testid: 'filter_select_all_cta_testid',
    filter_clear_cta_testid: 'filter_clear_cta_testid',
    filter_check_box_testid: 'filter_check_box_testid',
    createdby_test_id: 'createdby_test_id'
};

export const quoteDetailsDataLocators = {
    quote_details_transmit_quote_cta: 'quote_details_transmit_quote_cta',
    quote_details_edit_quote_cta: 'quote_details_edit_quote_cta',
    quote_details_close_modal_cta: 'quote_details_close_modal_cta',
    quote_details_estimated_tooltip_cta: 'quote_details_estimated_tooltip_cta',
    quote_details_already_in_cart_cta: ' quote_details_already_in_cart_cta',
    quote_details_empty_cart_continue_cta: 'quote_details_empty_cart_continue_cta',
    quote_details_alert_modal_title: 'quote_details_alert_modal_title',
    quote_details_alert_modal_warningQuestion: 'quote_details_alert_modal_warningQuestion',
    quote_details_cancel_quote_cta: 'quote_details_cancel_quote_cta',
    qoute_detail_non_Editable_banner: 'qoute_detail_non_Editable_banner'
};

export const viewQuoteDataLocators = {
    pickup_info_details_testid: 'pickup_info_details_testid',
    pickup_info_phone_number_testid: 'pickup_info_phone_number_testid',
    pickup_info_email_testid: 'pickup_info_email_testid'
};

export const editQuoteDataLocators = {
    review_your_changes_cta: 'review_your_changes_cta',
    discard_changes_and_exit_cta: 'discard_changes_and_exit_cta',
    cancel_editing_cta_testid: 'cancel_editing_cta_testid',
    back_to_quote_close_icon: 'back_to_quote_close_icon_cta'
};
