import moment from 'moment';
import { getHolidayList } from '../../global/utils/commonUtils';
import { logError } from '../../global/utils/logger';

const holidaysList = getHolidayList();
// Convert holiday dates to moment objects
const holidayDates = holidaysList?.map(date => moment(date));

export const getDifferenceinHours = (pickupDateTime, returnDateTime) => {
    const date1 = new Date(pickupDateTime);
    const date2 = new Date(returnDateTime);
    return Math.abs(date2 - date1) / 36e5;
};
export const getTimeStops = (start, end) => {
    let endTime = moment(end, 'hh:mm A');
    let startTime = moment(start, 'hh:mm A');
    // if (endTime.isBefore(startTime)) {
    //     endTime.add(1, 'day');
    // }
    let timeStops = [];
    let count = 0;
    while (startTime < endTime) {
        if (count > 0) {
            startTime.add(30, 'minutes');
        }
        // const currentTime = moment().add(30, 'minutes');
        let timeAfterOffset = '';
        // if (startTime > currentTime) {
        timeStops.push(new moment(startTime).format('hh:mm A'));
        count++;
        // }
    }
    return timeStops;
};
export const makeTimeInterval = (startTime = '') => {
    const start = startTime || moment();
    const remainder = 30 - (start.minute() % 30);
    const dateTime = moment(start).add(remainder, 'minutes');
    return dateTime;
};
export const tommorrowDate = () => {
    let tommorrowDate = moment().add(1, 'days');
    tommorrowDate = moment(tommorrowDate).format('LL');
    return tommorrowDate;
};
export const convertDateToMomentDateTimeNonUTC = (date, selTime) => {
    let dateMoment = date ? moment(date) : moment();
    let pickupTime = '';
    let currTime = moment();
    if (selTime) {
        pickupTime = moment(selTime, 'h:mm A');
    }
    const dateAndTime = dateMoment.set({
        hour: pickupTime ? pickupTime.get('hour') : currTime.get('hour'),
        minute: pickupTime ? pickupTime.get('minute') : currTime.get('minute'),
        second: 0,
        millisecond: 0
    });
    let dateTimeFormatted = moment(dateAndTime?.toDate()).format('YYYY-MM-DDTHH:mm:ss[Z]');
    return dateTimeFormatted;
};
export const addTPlusNDays = selectedStartDate => {
    const dow = moment(selectedStartDate).day();
    let days = 4;
    if (dow === 3) {
        days = 5;
    }
    if (dow === 2) {
        days = 6;
    }
    return days;
};
export const MakeArrayOfObjects = arr => {
    let arr2 = [];
    arr?.forEach(ele => {
        arr2.push({
            value: `${ele ? ele : ''}`,
            label: `${ele ? ele : ''}`
        });
    });
    return arr2;
};
export const makeArrayFromObjects = Obj => {
    let arr2 = [];
    for (let i in Obj) {
        arr2.push({
            identifier: i,
            value: Obj[i].value,
            number: Obj[i].number
        });
    }
    return arr2;
};
export const handleAnonymousIdFormatting = (guid = '') => {
    if (guid === '' || !guid) {
        return undefined;
    }
    let rxGetGuidGroups = /(\w{8})(\w{4})(\w{4})(\w{4})(\w{12})/;
    let formattedGUID = guid.replace(rxGetGuidGroups, '$1-$2-$3-$4-$5').toUpperCase();
    return formattedGUID;
};
export const setPrefilledAddressForCreateProject = (streetAddress, selectedCity) => {
    let addrLine1 = streetAddress?.split(',')[0]?.trim();
    if (addrLine1 == selectedCity) {
        addrLine1 = '';
    }
    return addrLine1;
};
/**
 * get total quantity, total price of rentals and addons in consumables
 *
 * @param {*}  selectedObj accept the selected object from context
 * @returns {}
 */
export const getTotalFromConsumables = selectedObj => {
    let totalRentalPrice = 0;
    let totalRentalQty = 0;
    let totalAddonPrice = 0;
    let totalAddonQty = 0;
    Object.keys(selectedObj).forEach(item => {
        let selectedArray = Object.keys(selectedObj[item]);
        if (selectedArray.includes('rentals')) {
            let rentalArray = Object.values(selectedObj[item]['rentals']);
            rentalArray.forEach(itemRental => {
                totalRentalQty += itemRental.qty;
                totalRentalPrice += itemRental.qty * itemRental.price;
            });
        }
        if (selectedArray.includes('addons')) {
            let addonArray = Object.values(selectedObj[item]['addons']);
            addonArray.forEach(itemAddon => {
                totalAddonQty += itemAddon.qty;
                totalAddonPrice += itemAddon.qty * itemAddon.price;
            });
        }
    });
    return { totalRentalPrice, totalRentalQty, totalAddonQty, totalAddonPrice };
};
export const areBothSlotsSelected = howToGetYourOrderDetails => {
    if (
        (howToGetYourOrderDetails?.selectedPickupTime && howToGetYourOrderDetails?.selectedReturnTime) ||
        (howToGetYourOrderDetails?.selectedPickUpTime?.label && howToGetYourOrderDetails?.selectedDeliveryTime?.label)
    ) {
        return true;
    }
    return false;
};

// Define the constant for the date format

export const isAfterStartTime = (startTime, endTime) => {
    const TIME_FORMAT = 'yyyy-MM-DD hh:mm A';

    if (!startTime || !endTime) {
        return false;
    }

    const dateFormat = moment()?.format('yyyy-MM-DD');
    const startTimeWithDate = moment(`${dateFormat} ${startTime}`, TIME_FORMAT);
    const endTimeWithDate = moment(`${dateFormat} ${endTime}`, TIME_FORMAT);

    return endTimeWithDate?.isAfter(startTimeWithDate);
};

export const findNextAvailableSlot = (slotData, date) => {
    try {
        let inputDate = moment(date)?.endOf('day');

        // Filter slots that are after the end of the given date
        let futureSlots = slotData?.filter(window => {
            let slotDate = moment(window?.start);
            return slotDate?.isAfter(inputDate);
        });

        // Find the next available slot
        let nextAvailableSlot = futureSlots?.find(window => {
            let slotDate = moment(window?.start)?.startOf('day');
            // Check if the day is not Saturday (6), Sunday (0), and not a holiday
            let isWeekend = slotDate?.day() === 0 || slotDate?.day() === 6;
            let isHoliday = holidayDates?.some(holiday => holiday?.isSame(slotDate, 'day'));
            return !isWeekend && !isHoliday;
        });
        return nextAvailableSlot?.start || '';
    } catch (error) {
        logError(error, false, 'findNextAvailableSlot');
        return '';
    }
};

// Function to adjust the date to the next weekday if it's a Saturday (6), Sunday (0), and holiday
export const adjustDate = date => {
    try {
        let slotDate = moment(date)?.startOf('day');
        const dayOfWeek = slotDate?.day();
        // Adjust for weekends
        if (dayOfWeek === 6) {
            // Saturday
            slotDate?.add(2, 'days'); // Move to Monday
        } else if (dayOfWeek === 0) {
            // Sunday
            slotDate?.add(1, 'days'); // Move to Monday
        }

        // Adjust for holidays
        while (holidayDates?.some(holiday => holiday?.isSame(slotDate, 'day'))) {
            slotDate?.add(1, 'days'); // Move to the next day
        }
        return slotDate;
    } catch (error) {
        logError(error, false, 'adjustDate function');
        return '';
    }
};
