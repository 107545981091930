import React, { memo, useEffect, useState } from 'react';
import { bool, func, string } from 'prop-types';
import { useIntl } from 'react-intl';
import { useCapUtils } from '../hooks/useCapUtils';
import useMedia from '../../../hooks/useMedia';
import { capDataLocator } from '../dataLocator';
import { VARIABLE_CONFIG } from '../../../constants/analyticsConstants/Variables';
import { MEDIA_TYPE } from '../../../constants/screenConstants';
import { useCheckAuthorityType } from '../../../hooks/useCheckUser';
import { usePageType } from '../../../hooks/usePageType';
import CloseIcon from '../../../resources/images/close.svg';
import LocationPin from '../../../resources/images/location-pin.svg';
import Button from '../../global/atoms/button/button';
import { AUTHORITY_TYPE } from '../../global/constants';
import JobsiteAddressAutoComplete from '../../global/modules/jobsitesAddressAutoComplete';
import SideDrawerModal from '../../global/modules/modals/SideDrawerModal';
import { EDIT_VIEW_DETAILS } from '../constants';
import { useFilterState } from '../filterContext';
import './rentalLocation.scss';

const RentalLocation = ({
    addressText,
    jobAndAddressState,
    setJobAndAddressState,
    isCurrentLocationDisabled,
    setIsCapInteracted,
    locationContainerRef,
    isAddressChanged,
    setIsAddressChanged,
    stringSearchedForSuggestion,
    setStringSearchedForSuggestion,
    onFocusRentalLocation,
    onAddressSelect,
    onJobsiteSelect
}) => {
    const capIntl = useIntl();
    const { getCapUserDetails, getCapLocationLabel } = useCapUtils();
    const [{ showAvsErrorInCap }] = useFilterState();
    const pageType = usePageType();
    const mediaType = useMedia();
    const [showDrawer, setShowDrawer] = useState(false);
    const authorityType = useCheckAuthorityType();
    const isP2PUser = authorityType === AUTHORITY_TYPE.P2P;
    const rentalLocationLabel =
        getCapUserDetails()?.rentalLocationText || capIntl.formatMessage({ id: 'cap:rental-location' });

    const chooseAccountAndJobsiteLabel = getCapLocationLabel(rentalLocationLabel);
    const jobsiteLabel = isP2PUser ? capIntl.formatMessage({ id: 'cap:jobsite' }) : rentalLocationLabel;
    const labelBasedOnEmptyField = addressText ? jobsiteLabel : chooseAccountAndJobsiteLabel;
    const isDesktopView = mediaType === MEDIA_TYPE.DESKTOP;
    const [{ projectDetails, editViewSource }, dispatch] = useFilterState();
    const [selectedProjectId, setSelectedProjectId] = useState('');
    const isHomePage = pageType === VARIABLE_CONFIG.PAGE_TYPE.HOME_PAGE;
    const isLocationPage = pageType === VARIABLE_CONFIG.PAGE_TYPE.LOCATION_PAGE;

    useEffect(() => {
        if (!isDesktopView && editViewSource === VARIABLE_CONFIG.CAP_VIEW_MODE.LOCATION_VIEW) {
            setShowDrawer(true);
            dispatch({ type: EDIT_VIEW_DETAILS, editViewSource: '' });
        }
    }, [editViewSource]);

    useEffect(() => {
        setSelectedProjectId(projectDetails?.selectedProjectJobId);
    }, [projectDetails?.selectedProjectLatititude]);

    const toggleDrawer = () => {
        setShowDrawer(!showDrawer);
    };

    const addressSelectHandler = (address, currentLocationDisabledState, placeId) => {
        onAddressSelect(address, currentLocationDisabledState, placeId);
        !isDesktopView && setShowDrawer(false);
    };

    const jobsiteSelectHandler = async (jobsite, fullAddress, currentLocationDisabledState) => {
        onJobsiteSelect(jobsite, fullAddress, currentLocationDisabledState);
        !isDesktopView && setShowDrawer(false);
    };

    const jobsiteAddressDrawerHeading = () => {
        return (
            <>
                <h5>{rentalLocationLabel}</h5>
                <Button
                    customButtonAriaLabel={capIntl.formatMessage({ id: 'cap:common-close-dialog' })}
                    onClick={toggleDrawer}>
                    <CloseIcon />
                </Button>
            </>
        );
    };
    const labelContent = () => (
        <label
            className="rental-location-label"
            htmlFor={isDesktopView || isP2PUser ? capDataLocator.rental_location_input_testid : ''}
            aria-label={labelBasedOnEmptyField}>
            <LocationPin />
            {labelBasedOnEmptyField}
        </label>
    );

    const labelContentForAutoComplete = () => {
        return isDesktopView || isP2PUser ? labelContent() : '';
    };

    const renderAutoComplete = () => {
        const locationClass = pageType !== VARIABLE_CONFIG.PAGE_TYPE.HOME_PAGE ? 'viewMode-cap-location' : '';
        const autocompleteWrapper = isDesktopView || isP2PUser ? 'cap-location' : 'cap-location-drawer';
        const errorClass = showAvsErrorInCap ? 'outline-error' : '';
        const placeholderText =
            isDesktopView || isP2PUser ? '' : capIntl.formatMessage({ id: 'cap:location-drawer-placeholder' });

        return (
            <JobsiteAddressAutoComplete
                inputId={capDataLocator.rental_location_input_testid}
                autocompleteWrapper={`${autocompleteWrapper} ${locationClass} ${errorClass}`}
                placeholderText={placeholderText}
                prefilledAddress={addressText}
                labelContent={labelContentForAutoComplete}
                selectedAddress={addressSelectHandler}
                onJobsiteSelect={jobsiteSelectHandler}
                isLocationPersisted={!isDesktopView}
                focusInput={!isDesktopView || editViewSource === VARIABLE_CONFIG.CAP_VIEW_MODE.LOCATION_VIEW}
                selectedProjectId={selectedProjectId}
                setSelectedProjectId={setSelectedProjectId}
                inputAriaLabel={isDesktopView || isP2PUser ? jobsiteLabel : ''}
                isAddressChanged={isAddressChanged}
                setIsAddressChanged={setIsAddressChanged}
                displayJobsiteModal={isP2PUser}
                disableCurrentLocation={isCurrentLocationDisabled}
                onLocationInputFocus={onFocusRentalLocation}
                setIsCapInteracted={setIsCapInteracted}
                jobAndAddressState={jobAndAddressState}
                setJobAndAddressState={setJobAndAddressState}
                stringSearchedForSuggestion={stringSearchedForSuggestion}
                setStringSearchedForSuggestion={setStringSearchedForSuggestion}
            />
        );
    };

    const renderRentalLocation = () => (
        <div
            className={`cap-location-container ${addressText && 'address-filled'}`}
            onClick={toggleDrawer}
            tabIndex={0}
            aria-label={rentalLocationLabel}>
            {labelContent()}
            {addressText && <div className="location-address">{addressText}</div>}
        </div>
    );

    return (
        <div
            ref={locationContainerRef}
            className={
                isHomePage || isLocationPage
                    ? 'rental-location-homepage-wrapper tooltip-location'
                    : 'rental-location-wrapper  tooltip-location'
            }>
            {isDesktopView || isP2PUser ? (
                renderAutoComplete()
            ) : (
                <>
                    {renderRentalLocation()}
                    {showDrawer && (
                        <SideDrawerModal
                            isModalOpen
                            handleModalToggle={toggleDrawer}
                            header={jobsiteAddressDrawerHeading()}
                            content={renderAutoComplete()}
                            modalContentClass="location-drawer-content"
                        />
                    )}
                </>
            )}
        </div>
    );
};

RentalLocation.defaultProps = {
    addressText: '',
    setJobAndAddressState: () => {},
    isCurrentLocationDisabled: false,
    setIsCapInteracted: () => {},
    locationContainerRef: () => {},
    isAddressChanged: false,
    setIsAddressChanged: () => {},
    stringSearchedForSuggestion: '',
    setStringSearchedForSuggestion: () => {},
    onFocusRentalLocation: () => {},
    onAddressSelect: () => {},
    onJobsiteSelect: () => {}
};

RentalLocation.propTypes = {
    addressText: string,
    setJobAndAddressState: func,
    isCurrentLocationDisabled: bool,
    setIsCapInteracted: func,
    locationContainerRef: func,
    isAddressChanged: bool,
    setIsAddressChanged: func,
    stringSearchedForSuggestion: string,
    setStringSearchedForSuggestion: func,
    onFocusRentalLocation: func,
    onAddressSelect: func,
    onJobsiteSelect: func
};

export default memo(RentalLocation);
