import React, { memo } from 'react';
import { bool, func } from 'prop-types';
import { useIntl } from 'react-intl';
import { MEDIA_TYPE } from '../../../constants/screenConstants';
import { STORAGE_CONFIG } from '../../../constants/storageConfig';
import useMedia from '../../../hooks/useMedia';
import Alert from '../../global/atoms/alert/alert';
import Button from '../../global/atoms/button';
import EditOnlyPersistantCapHeader from '../persistantCapHeader/EditOnlyPersistantCapHeader/EditOnlyPersistantCapHeader';
import { EDIT_VIEW_CLOSE } from '../constants';
import { capDataLocator } from '../dataLocator';
import { useFilterState } from '../filterContext';
import { useCapUtils } from '../hooks/useCapUtils';

const LocationCapHeader = ({
    saveCapDetailsHandler,
    isStoreLocationFetching,
    renderRentalDetailsView,
    isCapInteracted,
    setIsCapInteracted,
    setIsCapDetailsSaved,
    resetCapFields,
    setShowPickupStoreAlert,
    showPickupStoreAlert,
    setNoLocationPC,
    setShowAlertBanner,
    showAlertBanner,
    updateRentalViewDetails,
    sendAnalyticsForNoStoreNearBy,
    renderRentalDetailsDrawerContent
}) => {
    const capIntl = useIntl();
    const [{ isEditView }, dispatch] = useFilterState();
    const { getCapUserDetails } = useCapUtils();
    const mediaType = useMedia();
    const overridePC = JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.OVERRIDEPC) || '{}');

    const toggleEditMode = () => {
        dispatch({ type: EDIT_VIEW_CLOSE });
        setIsCapInteracted(false);
        localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.ISCAPDETAILSSAVED, true);
        setIsCapDetailsSaved(true);
        resetCapFields();
    };

    const capHeaderOverlayHandler = () => {
        dispatch({ type: EDIT_VIEW_CLOSE });
        saveCapDetailsHandler(false, true);
    };
    const renderPersistentDrawerView = () => (
        <>
            {renderRentalDetailsDrawerContent()}
            {renderAlertBannerInDrawer()}
        </>
    );

    const renderPersistentRentalView = () => <>{renderRentalDetailsView()}</>;

    const renderAlertBannerInDrawer = () => {
        if (showAlertBanner && !overridePC?.pc) {
            return (
                <Alert
                    localStyle="pickupStore__alert"
                    type={'warning'}
                    message={
                        <div>
                            {capIntl.formatMessage({ id: 'cap:pickup-no-store-warning-text-prefix' })}
                            <a
                                href={capIntl.formatMessage({ id: 'cap:no-address-helpline-ref' })}
                                className="pickupStore__alert__link"
                                title={capIntl.formatMessage({ id: 'cap:no-store-help-text' })}>
                                {capIntl.formatMessage({ id: 'cap:no-address-helpline' })}
                            </a>
                            {capIntl.formatMessage({ id: 'cap:pickup-no-store-warning-text-sufix' })}
                        </div>
                    }
                    dataTestId={capDataLocator.pickupStore_alert}
                />
            );
        }
    };

    const renderUpdateBtn = () => {
        const showShimmer = isStoreLocationFetching && isEditView;
        const startRentingBtnText =
            mediaType === MEDIA_TYPE.DESKTOP
                ? getCapUserDetails()?.rentingCTAText || capIntl.formatMessage({ id: 'cap:start-renting-button' })
                : capIntl.formatMessage({ id: 'cap:common-apply' });
        return (
            <Button
                type="button"
                className={`start-renting-btn ${showShimmer ? 'shimmer start-renting-btn__shimmer' : ''}`}
                buttonAriaLabel={startRentingBtnText}
                dataTestid={capDataLocator.renting_button_cta_testid}
                isdisabled={showShimmer}
                onClick={() => saveCapDetailsHandler(false)}>
                {startRentingBtnText}
            </Button>
        );
    };

    const renderMobileView = () => {
        return (
            <>
                <EditOnlyPersistantCapHeader
                    renderUpdateBtn={renderUpdateBtn}
                    renderRentalDetailsView={renderPersistentRentalView}
                    renderRentalDetailsDrawerContent={renderPersistentDrawerView}
                    toggleEditMode={toggleEditMode}
                    isCapInteracted={isCapInteracted}
                    isEditView={isEditView}
                    setShowPickupStoreAlert={setShowPickupStoreAlert}
                    showPickupStoreAlert={showPickupStoreAlert}
                    setNoLocationPC={setNoLocationPC}
                    setShowAlertBanner={setShowAlertBanner}
                    showAlertBanner={showAlertBanner}
                    updateRentalViewDetails={updateRentalViewDetails}
                    sendAnalyticsForNoStoreNearBy={sendAnalyticsForNoStoreNearBy}
                    capHeaderOverlayHandler={capHeaderOverlayHandler}
                />
            </>
        );
    };

    return mediaType == MEDIA_TYPE.DESKTOP ? (
        <>
            <button
                className={`modal__root ${isEditView ? 'modal__root-active' : 'modal__root-inactive'}`}
                onClick={capHeaderOverlayHandler}>
                <span className="visually-hidden">{capIntl.formatMessage({ id: 'cap:overlay-text' })}</span>
            </button>
            <div className={'cap-header'}>
                {renderRentalDetailsView()}
                <div className="start-renting-div">{renderUpdateBtn()}</div>
            </div>
        </>
    ) : (
        renderMobileView()
    );
};

LocationCapHeader.propTypes = {
    saveCapDetailsHandler: func,
    isStoreLocationFetching: bool,
    renderRentalDetailsView: func,
    isCapInteracted: bool,
    setIsCapInteracted: func,
    setIsCapDetailsSaved: func,
    resetCapFields: func,
    setShowPickupStoreAlert: func,
    showPickupStoreAlert: bool,
    setNoLocationPC: func,
    setShowAlertBanner: func,
    showAlertBanner: bool,
    updateRentalViewDetails: func,
    sendAnalyticsForNoStoreNearBy: func,
    renderRentalDetailsDrawerContent: func,
};

LocationCapHeader.defaultProps = {
    saveCapDetailsHandler: () => {},
    isStoreLocationFetching: false,
    renderRentalDetailsView: () => {},
    isCapInteracted: false,
    setIsCapInteracted: () => {},
    setIsCapDetailsSaved: () => {},
    resetCapFields: () => {},
    setShowPickupStoreAlert: () => {},
    showPickupStoreAlert: false,
    setNoLocationPC: () => {},
    setShowAlertBanner: () => {},
    showAlertBanner: false,
    updateRentalViewDetails: () => {},
    sendAnalyticsForNoStoreNearBy: () => {},
    renderRentalDetailsDrawerContent: () => {},
};

export default memo(LocationCapHeader);
