export const CART_OVERLAYS = {
    VIEW_CART: 'view_cart',
    ACCOUNT: 'account',
    LOCATION: 'location',
    ROUND_TRIP: 'round_trip',
    CART: 'cart',
    ESTIMATE: 'estimate'
};

export const VIEW_CART = {
    START_DATE: 'startDate',
    END_DATE: 'endDate',
    LOCATION: 'location',
    LAT: 'lat',
    LONG: 'long',
    STATE: 'jobSiteState',
    ZIP_CODE: 'jobSiteZip',
    CITY: 'jobSiteCity',
    ADDRESS2: 'jobSiteAddr2',
    IN_STORE: 'isInStorePickup',
    SHOW_DELIVERY_ESTIMATE: 'showDeliveryEstimate',
    SHOW_CREATE_PROJECT: 'showCreateProject',
    CREATE_PROJECT_SOURCE: 'createProjectSource',
    SHOW_ADDONS_OVERLAY: 'showAddOnsOverlay',
    CHECK_PC_INTERVAL: 'checkPCInterval',
    SHOW_INACTIVE_PC_ALERT: 'inactivePCAlert',
    PCLAT: 'pcLat',
    PCLONG: 'pcLong',
    DISTANCEFROMPC: 'distanceFromPc',
    PC: 'pc'
};

export const LOADERS = {
    IS_ESTIMATES_LOADING: 'isEstimatesLoading'
};

export const HOW_TO_GET_YOUR_ORDER = {
    SELECTED_PICKUP_TIME: 'selectedPickupTime',
    SELECTED_RETURN_TIME: 'selectedReturnTime',
    BEST_MATCH_STORES_DATA: 'bestMatchStoresData',
    CLOSEST_STORES_DATA: 'closestStoresData',
    SELECTED_DELIVERY_TIME: 'selectedDeliveryTime',
    SET_PICKUP_TIME: 'selectedPickUpTime',
    SELECTED_STORE_DETAILS: 'selectedStoreDetails',
    ESTIMATED_TOTAL: 'estimatedTotal',
    PICKUP_TYPE: 'pickupType',
    SOMEONE_ELSE: 'someoneElse',
    IS_BEST_MATCH: 'isBestMatch',
    ONE_DAY_DIFFERENCE_IN_SECONDS: 86400,
    STORES_API_STATUS: 'storesAPIStatus',
    SELECTED_START_DATE: 'selectedStartDate',
    SELECTED_END_DATE: 'selectedEndDate',
    EMPTY_DELIVERY_PICKUP_TIME: 'emptyDeliveryPickupTime'
};

export const GUEST_USER_DETAILS = {
    FIRST_NAME: 'firstName',
    LAST_NAME: 'lastName',
    PHONE_NUMBER: 'phoneNumber',
    EMAIL: 'email'
};

export const ADDRESS_COMPONENTS = {
    AL2: 'administrative_area_level_2',
    AL3: 'administrative_area_level_3',
    LOCALITY: 'locality',
    AL1: 'administrative_area_level_1',
    POSTAL: 'postal_code',
    STREET_NO: 'street_number',
    COUNTRY: 'country',
    ROUTE: 'route',
    NEIGHBORHOOD: 'neighborhood',
    SUBLOCALITY: 'sublocality'
};

export const ADDRESS_COMPONENT_TYPE = {
    POSTAL_CODE: 'POSTAL_CODE',
    STATE: 'STATE',
    CITY: 'CITY',
    AREA_L2: 'AREA_L2',
    ROUTE: 'ROUTE',
    STREET: 'STREET',
    HOME_ADDRESS: 'HOME_ADDRESS',
    COUNTRY: 'COUNTRY',
    NEIGHBORHOOD: 'NEIGHBORHOOD',
    SUBLOCALITY: 'SUBLOCALITY'
};

export const DELIVERY_CHARGE_FLAG = {
    FREE: 'free',
    TBD: 'tbd',
    ZERO_DELIVERY_WITHOUT_ZIP: 'zeroDeliveryWithoutZip',
    SHOW_ESTIMATES: 'showEstimates'
};

export const CART_AGE = { GUEST_USER: 30 * 24 * 60 * 60, AUTHENTICATED_USER: 75 * 60 };
