import moment from 'moment';
import * as moment2 from 'moment-timezone';
import { SET_HOW_TO_GET_YOUR_ORDER_FIELDS } from '../../../aem-core-components/actions/constants';
import { HOW_TO_GET_YOUR_ORDER } from '../../../constants/cartConstants';
import { convertDateToMomentDateTime } from '../../global/utils/commonUtils';
import { addTPlusNDays } from './checkoutUtils';
import { updateStoreBasedOnSelectedStore } from '../../global/utils/computeAddressUtil';
import { getLeadTime } from './editModuleFunctions';
import { getStoresData } from '../../global/modules/Stores/Api/getStoresData';
import { STORAGE_CONFIG } from '../../../constants/storageConfig';
import { USER_TYPE } from '../../../constants/userDetailsConstants';
export const getStores = async (
    viewCart,
    selectedStartDate,
    selectedEndDate,
    projectDetails,
    userType,
    dispatch,
    assets,
    howToGetYourOrderDetails,
    isDelivery,
    isExpressDelivery
) => {
    const isCreditCustomer  = userType === USER_TYPE.CREDIT ;
    const latSelection = isCreditCustomer => {
        if (isCreditCustomer) {
            return projectDetails.selectedProjectLatititude;
        } else return viewCart.lat;
    };
    const longSelection = isCreditCustomer => {
        if (isCreditCustomer) {
            return projectDetails.selectedProjectLongitude;
        } else return viewCart.long;
    };
    //TODO- check if time needs to be considered for pickupDateTiem,returnDateTime
    const getStoresPayload = {
        latitude: Number(latSelection(isCreditCustomer)),
        longitude: Number(longSelection(isCreditCustomer)),
        pickupDateTime: convertDateToMomentDateTime(selectedStartDate, howToGetYourOrderDetails.selectedPickupTime),
        returnDateTime: convertDateToMomentDateTime(selectedEndDate, howToGetYourOrderDetails.selectedReturnTime),
        assets,
        isDelivery,
        isExpressDelivery
    };
    try {
        let deliveryStoreIndex = 0;
        const { data, status } = await getStoresData({ ...getStoresPayload });
        deliveryStoreIndex = updateStoreBasedOnSelectedStore(data, deliveryStoreIndex);
        const payload = {
            pc: data?.data?.[deliveryStoreIndex]?.pc,
            lat: data?.data?.[deliveryStoreIndex]?.latitude,
            long: data?.data?.[deliveryStoreIndex]?.longitude,
            localLat: Number(latSelection(isCreditCustomer)),
            localLong: Number(longSelection(isCreditCustomer)),
            distance: data?.data?.[deliveryStoreIndex]?.drivingDistanceFromJobsite,
            specialtyTypes: data?.data?.[deliveryStoreIndex]?.specialtyTypes
        };
        sessionStorage.setItem(STORAGE_CONFIG.SESSION_STORAGE.SOURCEPCOBJ, JSON.stringify(payload));
        dispatch({
            type: SET_HOW_TO_GET_YOUR_ORDER_FIELDS,
            key: HOW_TO_GET_YOUR_ORDER.BEST_MATCH_STORES_DATA,
            value: data
        });
        return { data, status, deliveryStoreIndex };
    } catch (err) {
        dispatch({
            type: SET_HOW_TO_GET_YOUR_ORDER_FIELDS,
            key: HOW_TO_GET_YOUR_ORDER.BEST_MATCH_STORES_DATA,
            value: { data: [] }
        });
        dispatch({ type: 'endLoading' });
        return [];
    }
    // return getStoresData({ ...getStoresPayload })
    //     .then(({ data }) => {
    //         dispatch({ type: SET_HOW_TO_GET_YOUR_ORDER_FIELDS, key: HOW_TO_GET_YOUR_ORDER.BEST_MATCH_STORES_DATA , value: data });
    //         return data;
    //     })
    //     .catch(error => {
    //         dispatch({ type: SET_HOW_TO_GET_YOUR_ORDER_FIELDS, key: HOW_TO_GET_YOUR_ORDER.BEST_MATCH_STORES_DATA , value: { data: [] } });
    //     });
};
export const getClosestStores = (
    viewCart,
    selectedStartDate,
    selectedEndDate,
    projectDetails,
    userType,
    dispatch,
    assets,
    howToGetYourOrderDetails,
    isDelivery,
    isExpressDelivery
) => {
    const isCreditCustomer  = userType === USER_TYPE.CREDIT;
    const latSelection = isCreditCustomer => {
        if (isCreditCustomer) {
            return projectDetails.selectedProjectLatititude;
        } else return viewCart.lat;
    };
    const longSelection = isCreditCustomer => {
        if (isCreditCustomer) {
            return projectDetails.selectedProjectLongitude;
        } else return viewCart.long;
    };
    const addDays = addTPlusNDays(selectedStartDate);
    let closestStoreStartDate = moment(selectedStartDate).add(addDays, 'days');
    const getStoresPayload = {
        latitude: Number(latSelection(isCreditCustomer)),
        longitude: Number(longSelection(isCreditCustomer)),
        pickupDateTime: convertDateToMomentDateTime(closestStoreStartDate, howToGetYourOrderDetails.selectedPickupTime),
        returnDateTime: convertDateToMomentDateTime(selectedEndDate, howToGetYourOrderDetails.selectedReturnTime),
        assets,
        isDelivery,
        isExpressDelivery
    };
    return getStoresData({ ...getStoresPayload })
        .then(({ data, status }) => {
            dispatch({
                type: SET_HOW_TO_GET_YOUR_ORDER_FIELDS,
                key: HOW_TO_GET_YOUR_ORDER.CLOSEST_STORES_DATA,
                value: data
            });
            dispatch({
                type: SET_HOW_TO_GET_YOUR_ORDER_FIELDS,
                key: HOW_TO_GET_YOUR_ORDER.STORES_API_STATUS,
                value: status
            });
            dispatch({ type: 'endLoading' });
            // setContinueSelected(true);
        })
        .catch(error => {
            dispatch({
                type: SET_HOW_TO_GET_YOUR_ORDER_FIELDS,
                key: HOW_TO_GET_YOUR_ORDER.CLOSEST_STORES_DATA,
                value: { data: [] }
            });
            dispatch({ type: 'endLoading' });
            // setContinueSelected(true);
        });
};
export const filterStores = (storeStype, startDate) => {
    let date = moment(startDate);
    const currentDatePlusOne = moment().add(1, 'days');
    return storeStype?.data?.filter(data => {
        return (
            (!date.isSameOrBefore(currentDatePlusOne) || data?.isLastResortBranch || data?.fulfillmentPercent === 1) &&
            data?.isOpenOnDateOut
        );
    });
};
export const checkFulfillmentInstore = (store, startDate) => {
    let date = moment(startDate);
    const currentDatePlusOne = moment().add(1, 'days');
    return (
        (!date.isSameOrBefore(currentDatePlusOne) || store?.isLastResortBranch || store?.fulfillmentPercent === 1) &&
        store?.isOpenOnDateOut
    );
};
export const hasDateEllapsed = (currentOffSet, startDate) => {
    const estDate = moment(currentOffSet).format('LL');
    const chosenStartDate = moment(startDate).format('LL');
    if (moment(estDate).isAfter(chosenStartDate)) {
        return true;
    }
    return false;
};
export const hasTimeEllapsed = (viewCart, howToGetYourOrderDetails, timeZoneID, cart) => {
    let currentDateTime = moment();
    currentDateTime = moment(currentDateTime).add(getLeadTime(cart), 'hours');
    const startdate = new Date(viewCart?.startDate);
    let dateMoment = moment(startdate);
    let selectedDateTime;
    if (viewCart?.isInStorePickup) {
        let pickupTime = moment(howToGetYourOrderDetails.selectedPickupTime, 'hh:mm A');
        selectedDateTime = dateMoment.set({
            hour: pickupTime.get('hour'),
            minute: pickupTime.get('minute'),
            second: 0,
            millisecond: 0
        });
    } else {
        let timeString = howToGetYourOrderDetails?.selectedDeliveryTime?.label.split('-')[0];
        timeString = timeString.trim();
        let deliveryTime = moment(timeString, 'hh:mm A');
        selectedDateTime = dateMoment.set({
            hour: deliveryTime.get('hour'),
            minute: deliveryTime.get('minute'),
            second: 0,
            millisecond: 0
        });
    }
    selectedDateTime = selectedDateTime.format('YYYY-MM-DDTHH:mm:ss');
    selectedDateTime = moment2.tz(selectedDateTime, timeZoneID);
    if (moment(selectedDateTime).isBefore(currentDateTime)) {
        return true;
    }
    return false;
};