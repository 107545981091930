import React, { memo, useEffect, useRef, useState } from 'react';
import { useMutation } from '@apollo/client';
import { useIntl } from 'react-intl';
import MUTATION_CREATE_CART from '../../../../aem-core-components/queries/mutation_create_guest_cart.graphql';
import SBR_CART_DEACTIVATE from './mutations/mutation_cart_deactivate';
import { usePageType } from '../../../../hooks/usePageType';
import { useCartState } from '../../../../contexts/cart';
import { useAnalyticsContext } from '../../../../config/GoogleTagManagerEvents';
import Button from '../../atoms/button';
import BackToQuote from './backToQuote/BackToQuote';
import { useCookieValue } from '../../../../aem-core-components/utils/hooks';
import { checkIsEditQuoteFlow } from '../../../global/utils/commonUtils';
import { redirectToQuotesPageWithQuoteId } from '../../../search/utils/searchUtils';
import { logError } from '../../utils/logger';
import AlertCircle from '../../../../resources/images/alert-white-circle.svg';
import './utilityBanner.scss';
import { editQuoteDataLocators } from '../../../quotes/dataLocators';
import { EVENT_P2P_NAMES_CONFIG } from '../../../../constants/analyticsConstants/ProcureToPay';
import { STORAGE_CONFIG } from '../../../../constants/storageConfig';
import { ENV_CONFIG } from '../../../../constants/envConfig';
import { VARIABLE_CONFIG } from '../../../../constants/analyticsConstants/Variables';

const { SESSION_STORAGE, LOCAL_STORAGE, COOKIES } = STORAGE_CONFIG;

const UtilityBanner = props => {
    const [sbrCartDeactivate] = useMutation(SBR_CART_DEACTIVATE);
    const [createCartMutation] = useMutation(MUTATION_CREATE_CART);
    const [cartId, setCartCookie] = useCookieValue(COOKIES.CIF_CART);
    const intl = useIntl();
    const displayQuoteId = localStorage.getItem(LOCAL_STORAGE.DISPLAY_QUOTE_ID) || '';
    const [isOpen, setIsOpen] = useState(false);
    const [showBanner, setShowBanner] = useState(false);
    const isEditQuoteFlow = checkIsEditQuoteFlow();
    const { sendEventsForP2PCtaClick } = useAnalyticsContext();
    // using this for hiding the banner after save
    const [{ cartId: miniCartId }, cartDispatch] = useCartState();
    const utilityBannerRef = useRef(null);
    const pageType = usePageType();

    const checkSearchPageUtilityBanner = () => {
        try {
            if (pageType === VARIABLE_CONFIG.PAGE_TYPE.SEARCH_PAGE) {
                const getHeader = ENV_CONFIG.HEADER;

                // Cache DOM elements
                const globalheaderSearch = document.querySelector('.globalheader__search');
                const globalP2pretailBanner = document.querySelector('.punchout_banner');
                const utilityBanner = utilityBannerRef?.current;
                const utilityBannerParent = utilityBanner?.parentElement;

                // Get heights
                const utilityBannerHeight = utilityBanner?.clientHeight || 0;  // Default to 0 if null
                const globalP2pretailBannerHeight = globalP2pretailBanner?.clientHeight || 0;

                // Set styles only when the elements are present
                if (utilityBannerParent) {
                    utilityBannerParent.classList.add('static_banner_style');
                    utilityBannerParent.style.setProperty('top', `${globalP2pretailBannerHeight}px`);
                }

                if (globalheaderSearch) {
                    // Adjust the top for the search header
                    globalheaderSearch.style.setProperty('top', `${utilityBannerHeight + globalP2pretailBannerHeight}px`);

                    // Calculate new padding for the header
                    const setNewPadding = globalheaderSearch.clientHeight + utilityBannerHeight;

                    if (getHeader) {
                        getHeader.style.cssText = `z-index: initial; padding-top: ${setNewPadding}px !important;`;
                    }
                }
            }
        }
        catch (error) {
            logError(error, false, 'checkSearchPageUtilityBanner func');
        }
    };

    useEffect(() => {
        try {
            if (isEditQuoteFlow) {
                setShowBanner(true);
                document.querySelector('.header__checkout')?.classList?.add('header__checkout-none');
                document.querySelector('.header__logo-wrap a')?.classList?.add('nopointer');
                document.querySelector('.footer__bottom-logo a')?.classList?.add('nopointer');
                document.querySelector('.header__search__right .miniaccount')?.classList?.add('my_account_dnone');
                document.querySelector('.header__menu__left .help_link')?.classList?.add('help_link_dnone');
                checkSearchPageUtilityBanner();
            } else {
                // using this for showing the header bar after save success, after utility banner hide
                document.querySelector('.header__checkout')?.classList?.remove('header__checkout-none');
            }
        } catch (error) {
            logError(error, false, 'UtilityBanner line 37');
        }
    }, [isEditQuoteFlow, utilityBannerRef?.current]);

    const cartDeactivate = () => {
        sbrCartDeactivate({ variables: { cartId }, fetchPolicy: 'network-only' })
            .then(({ data: sbrCartDeactivateData, error: sbrCartDeactivateError }) => {
                // Check if sbrCartDeactivate was successful before proceeding
                if (!sbrCartDeactivateError) {
                    return createCartMutation();
                } else {
                    // Handle sbrCartDeactivate error if needed
                    throw new Error('sbrCartDeactivate failed');
                }
            })
            .then(({ data: createCartMutationData, error: createCartMutationError }) => {
                const newCartId = createCartMutationData.createEmptyCart;
                setCartCookie(newCartId);
                // overriding the cartId with new cartId in userprofile and profileresponse
                // let profileResponseStorage = JSON.parse(sessionStorage.getItem(SESSION_STORAGE.PROFILERESPONSE) || {});
                let userProfileStorage = JSON.parse(sessionStorage.getItem(SESSION_STORAGE.USERPROFILE) || {});
                // profileResponseStorage.generateCustomerTokenUsingOkta.cart_token = newCartId;
                userProfileStorage.cart_token = newCartId;
                // sessionStorage.setItem(SESSION_STORAGE.PROFILERESPONSE, JSON.stringify(profileResponseStorage));
                sessionStorage.setItem(SESSION_STORAGE.USERPROFILE, JSON.stringify(userProfileStorage));
                // redirect to Quote List page
                redirectToQuoteListPage();
            })
            .catch(error => {
                logError(error, false, 'Discard changes modal | sbr cart deactivate');
            });
    };

    const redirectToQuoteListPage = () => {
        try {
            // updating quote related data in storage
            localStorage.setItem(LOCAL_STORAGE.SHOWEDITQUOTEBANNER, false);
            localStorage.removeItem(LOCAL_STORAGE.TRANSMITTED_QUOTE_ID);
            localStorage.removeItem(LOCAL_STORAGE.DISPLAY_QUOTE_ID);
            sessionStorage.setItem(SESSION_STORAGE.REDIRECTED_TO_EDIT_FLOW, true);

            // redirecting to quote details page
            redirectToQuotesPageWithQuoteId(displayQuoteId);
        } catch (error) {
            logError(error, false, 'onDiscard changes click');
        }
    };

    const handleCancelEditing = () => {
        sendEventsForP2PCtaClick(
            EVENT_P2P_NAMES_CONFIG.P2P_CTA_CLICKED,
            EVENT_P2P_NAMES_CONFIG.P2P_LINK_NAME_CANCEL_EDITING,
            EVENT_P2P_NAMES_CONFIG.P2P_LINK_TYPE_ANCHOR,
            EVENT_P2P_NAMES_CONFIG.P2P_LINK_LOCATION_BODY,
            window?.location?.pathname || EVENT_P2P_NAMES_CONFIG.P2P_LINK_DESTINATION_CANCEL_EDITING,
            { quote_id: displayQuoteId }
        );
        setIsOpen(!isOpen);
    };

    return (
        <>
            {/*  using miniCartId for hiding the banner after save on edit */}
            {showBanner && miniCartId && (
                <>
                    <div className="utility_banner" ref={utilityBannerRef}>
                        <div className="container utility_banner_container">
                            <div className="utility_banner_header">
                                <div className="utility_banner_header_text">
                                    <AlertCircle tabindex="-1" aria-hidden="true" />{' '}
                                    <p>
                                        {intl.formatMessage({ id: 'editquote:utility-banner-text' })} #{displayQuoteId}
                                    </p>
                                </div>
                                <Button
                                    type="button"
                                    data-testid={editQuoteDataLocators.cancel_editing_cta_testid}
                                    onClick={handleCancelEditing}
                                    className="button button-primary cancel_edit_cta"
                                    customButtonAriaLabel={intl.formatMessage({
                                        id: 'editquote:utility-button-aria-label-cancel-edit'
                                    })}>
                                    {intl.formatMessage({ id: 'editquote:utility-button-text-cancel-edit' })}
                                </Button>
                            </div>
                        </div>
                    </div>
                    <BackToQuote
                        isOpen={isOpen}
                        redirectToQuoteListPage={cartDeactivate}
                        handleBackToQuoteModal={handleCancelEditing}
                    />
                </>
            )}
        </>
    );
};

export default memo(UtilityBanner);
