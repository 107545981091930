import { gql } from '@apollo/client';

const ADD_WISHLIST_ITEM_TO_CART = gql`
    mutation addWishlistItemToCart($wishlistId: ID!, $wishlistItems: [WishlistItemInputnew!]!, $accountId: Int!) {
        addWishlistItemsToCart(wishlistId: $wishlistId, wishlistItems: $wishlistItems, accountId: $accountId) {
            wishlist {
                name
                id
                items_count
            }
            status
            add_wishlist_items_to_cart_user_errors {
                message
                code
                wishlistId
            }
            cart {
                total_quantity
                items {
                    __typename
                    uid
                    quantity
                    prices {
                        price {
                            currency
                            value
                        }
                        row_total {
                            currency
                            value
                        }
                    }
                    product {
                        name
                        sku
                        isOverSize
                        isOverWeight
                        category_name
                        parent_category_name
                        category_path
                        productcatsortindex
                        thumbnail {
                            url
                        }
                    }
                }
            }
        }
    }
`;

export default ADD_WISHLIST_ITEM_TO_CART;
