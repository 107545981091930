import { array, number, object, string } from 'prop-types';

export const EVENT_ECOMMERCE_NAMES_CONFIG = {
    ECOMMERCE_PRODUCT_CLICK: 'productClick',
    ECOMMERCE_DETAIL: 'ecommerce.detail',
    ECOMMERCE_ADD: 'ecommerce.add',
    ECOMMERCE_REMOVE: 'ecommerce.remove',
    ECOMMERCE_QUANTITY: 'ecommerce.quantity',
    ECOMMERCE_CHECKOUT: 'ecommerce.checkout',
    ECOMMERCE_CHECKOUT_OPTION: 'ecommerce.checkout_option',
    ECOMMERCE_PURCHASE: 'ecommerce.purchase',
    ECOMMERCE_PAGE: 'ecommerce.page',
    ECOMMERCE_UPDATE_VIRTUAL_URL: 'updatevirtualpath',
    ECOMMERCE_CHECKOUT_STARTED: 'checkout_started',
    ECOMMERCE_CHECKOUT_VIEWED: 'cart_viewed',
    ECOMMERCE_CART_VIEWED: 'cart_viewed',
    ECOMMERCE_LOCATION_STARTED: 'form_started',
    ECOMMERCE_FIELD_NAME_US: 'country select US',
    ECOMMERCE_FIELD_NAME_CANADA: 'country select CANADA',
    ECOMMERCE_FIELD_NAME_ADDRESS: 'address select',
    ECOMMERCE_FIELD_NAME_PROJECT_SEARCH: 'project search',
    ECOMMERCE_FIELD_NAME_ADDRESS_TYPE: 'address type select',
    ECOMMERCE_FIELD_NAME_ACCOUNT: 'account select',
    ECOMMERCE_FIELD_NAME_ACCOUNT_SEARCH: 'account search select',
    ECOMMERCE_FIELD_NAME_PROJECT: 'project select',
    ECOMMERCE_FIELD_FORM_PROJECT_SEARCH: 'project search',
    ECOMMERCE_FIELD_NAME_SIGNIN: 'signIn select',
    ECOMMERCE_FIELD_FORM_ADDRESS_EVENT: 'form_error',
    ECOMMERCE_LOCATION_MODAL_FORM_NAME: 'modal - your location',
    ECOMMERCE_FIELD_FORM_ADDRESS_FIELD: 'address',
    ECOMMERCE_FIELD_FORM_ACCOUNT_FIELD: 'account',
    ECOMMERCE_FIELD_FORM_ADDRESS_ERROR: 'Address is invalid',
    ECOMMERCE_FIELD_FORM_ACCOUNT_ERROR: 'Account is invalid',
    ECOMMERCE_FIELD_FORM_PROJECT_ERROR: 'Project is invalid',
    ECOMMERCE_FIELD_FORM_SUBMITTED_EVENT: 'form_submitted',
    ECOMMERCE_FIELD_FORM_SUBMITTED_LINK_NAME: 'set location',
    ECOMMERCE_FIELD_FORM_SUBMITTED_LINK_TYPE: 'button',
    ECOMMERCE_FIELD_LOCATION_SUBMITTED_EVENT: 'set_location_completed',
    ECOMMERCE_FIELD_LOCATION_VIEWED_EVENT: 'set_location_viewed',
    ECOMMERCE_CHECKOUT_STEP_VIEWED: 'step_viewed',
    ECOMMERCE_CHECKOUT_STEP_COMPLETED: 'step_completed',
    ECOMMERCE_PAGE_VIEWED: 'page_viewed',
    ECOMMERCE_CART_TITLE: 'Cart - Authentication',
    ECOMMERCE_CART_LOCATION: '/cart/authentication',
    ECOMMERCE_CTA_CLICKED: 'cta_clicked',
    ECOMMERCE_DATA: 'ecommerce.data',
    ECOMMERCE_FORM_SUBMITTED: 'form_submitted',
    ECOMMERCE_FORM_ERROR: 'form_error',
    ECOMMERCE_DATE_FORM_NAME: 'modal - datepicker',
    ECOMMERCE_END_DATE_FORM_FIELD: 'end_date',
    ECOMMERCE_START_DATE_FORM_FIELD: 'start_date',
    ECOMMERCE_VIEW_ITEM_LIST: 'view_item_list',
    ECOMMERCE_ADDED_TO_CART: 'added_to_cart',
    ECOMMERCE_ITEM_SELECTED: 'item_selected',
    ECOMMERCE_FORM_VIEWED: 'form_viewed',
    ECOMMERCE_DATE_PICKER_INTERACTION: 'form_interaction',
    ECOMMERCE_DATE_PICKER_LINK_NAME: 'Done',
    ECOMMERCE_DATE_PICKER_LINK_TYPE: 'button',
    ECOMMERCE_DATE_PICKER_FORM_COMPLETED: 'form_completed',
    ECOMMERCE_DATE_PICKER_FORM_RESET_DATES: 'Reset dates',
    ECOMMERCE_DATE_PICKER_FORM_RESET_TYPE: 'text',
    ECOMMERCE_DATE_PICKER_FORM_CLOSE: 'Close',
    ECOMMERCE_DATE_PICKER_FORM_CLOSE_CHAT: 'Chat with us',
    ECOMMERCE_VIEW_SIMILAR_ITEMS_LINK_TYPE: 'link',
    ECOMMERCE_VIEW_SIMILAR_ITEMS_LINK_LOCATION: 'minicart',
    ECOMMERCE_ITEMS_UNAVAILABLE_FORM: 'modal - items unavailable',
    ECOMMERCE_ITEMS_UNAVAILABLE_FORM_LINK_LOCATION: 'modal',
    ECOMMERCE_ITEMS_UNAVAILABLE_FORM_MODAL_ICON: 'icon',
    ECOMMERCE_BACK_TO_CART_AND_CONTINUE_LINK_TYPE: 'button',
    ECOMMERCE_CONTINUE_LINK_DESTINATION: '/checkout/',
    ECOMMERCE_CCA_FORM_NAME: 'cca',
    ECOMMERCE_CCA_EXIT_BUTTON_EVENT: 'cca_exited',
    ECOMMERCE_CCA_COMPLETED_BUTTON_EVENT: 'form_completed',
    ECOMMERCE_CCA_FORM_VIEW_EVENT: 'step_viewed',
    ECOMMERCE_CCA_STEP_COMPLETED: 'step_completed',
    ECOMMERCE_CCA_VERIFY_MODAL_COMPLETED: 'form_completed',
    ECOMMERCE_CCA_VERIFY_MODAL_INTERACTION: 'form_interaction',
    ECOMMERCE_CAP_HEADER: 'cap header',
    ECOMMERCE_FORM_INTERACTED: 'form_interacted',
    ECOMMERCE_CAP_LOCATION: 'location',
    ECOMMERCE_CAP_USE_CURRENT_LOCATION_LINK: 'Use Current Location',
    ECOMMERCE_CAP_LINK_TYPE: 'anchor',
    ECOMMERCE_CAP_LINK_TYPE_IMAGE: 'image',
    ECOMMERCE_CAP_LINK_LOCATION: 'body',
    ECOMMERCE_SEE_ALL_JOBSITES: 'See all jobsites',
    ECOMMERCE_CAP_START_RENTING: 'Start renting',
    ECOMMERCE_CAP_APPLY: 'Apply',
    ECOMMERCE_CAP_EDIT: 'Edit',
    ECOMMERCE_CAP_MATCHING_ADDRESS: 'Matching Address',
    ECOMMERCE_CAP_SUGGESTED_JOBSITE: 'Suggested Jobsite',
    ECOMMERCE_CAP_JOBSITE_ADDRESS: 'Jobsite Address',
    ECOMMERCE_CAP_HERO: 'cap hero',
    ECOMMERCE_CAP_DELIVERY_METHOD: 'delivery_method',
    ECOMMERCE_CAP_PICKUP_STORE: 'pickup_store',
    ECOMMERCE_NO_STORE_NEARBY: 'no_pc_near_address_viewed',
    ECOMMERCE_SET_PC_COMPLETE: 'set_pc_completed',
    ECOMMERCE_SET_PC_VIEWED: 'set_pc_viewed',
    ECOMMERCE_ADD_TO_CART: 'add_to_cart',
    ECOMMERCE_CCA_APPLICATION_SUBMISSION_EVENT: 'application_completed',
    ECOMMERCE_CCA_APPLICATION_APPROVED: 'CCA Application approved',
    ECOMMERCE_CCA_APPLICATION_REVIEW: 'CCA Application review',
    ECOMMERCE_CCA_APPLICATION_ERROR: 'application_error',
    ECOMMERCE_CCA_MODAL_HELP: 'modal-help',
    ECOMMERCE_CCA_MODAL_EXIT: 'modal-exit',
    ECOMMERCE_CCA_INFORMATION: 'information',
    ECOMMERCE_CCA_CLOSE: 'close',
    ECOMMERCE_CLOSE: '#close',
    ECOMMERCE_CCA_BUTTON: 'button',
    ECOMMERCE_CCA_MODAL: 'modal',
    ECOMMERCE_CCA_MODAL_EXEMPT: 'modal-exempt',
    ECOMMERCE_CCA_MODAL_CCATIMEOUT: 'modal-CcaTimeout',
    ECOMMERCE_CCA_MODAL_INFOMODAL: 'modal-InfoModal',
    ECOMMERCE_CCA_MODAL_VERIFY_ADDRESS: 'modal-Verify Address',
    ECOMMERCE_CCA_ERROR: 'error',
    ECOMMERCE_CCA_INPUT_NEEDED: 'input needed',
    ECOMMERCE_CCA_ADDRESS_SUGGESTIONS: 'Address Suggestions',
    ECOMMERCE_CCA_FORM_STARTED: 'form_started',
    ECOMMERCE_CCA_FORM_ERROR: 'form_error',
    ECOMMERCE_CCA_COMPLETED_BUTTON_EVENT: 'form_completed',
    ECOMMERCE_MODAL_JOIN_ACCOUNT: 'modal-joinAccount',
    ECOMMERCE_MODAL_VERIFICATION_FAILED: 'modal-verificationFailed',
    ECOMMERCE_MODAL_VERIFICATION_TIMEOUT: 'modal-verificationTimeout',
    ECOMMERCE_MODAL_BOTH_ACCOUNT: 'modal-bothTypesOfAccount',
    ECOMMERCE_MODAL_DRIVER_LICENSE: 'modal-driverLicense',
    ECOMMERCE_MODAL_VERIFY_ADDRESS: 'modal-verifyAddress',

    ADD_MORE_EQUIPMENTS_TO_THE_QUOTE: 'Add more equipment to this quote',
    TRANSMIT_QUOTE: 'Transmit Quote',
    EDIT_QUOTE: 'Edit Quote',
    TRANSMIT_QUOTE_DESTINATION_LINK: '#transmitquote',
    ADD_MORE_EQUIPMENTS_TO_THE_QUOTE_DESTINATION_LINK: '#addmoreequipmenttothisquote',
    EDIT_QUOTE_DESTINATION_LINK: '#editquote',
    ECOMMERCE_FARVORITES: 'favorites_viewed',
    ECOMMERCE_FARVORITES_DESTINATION_CREATELIST: '#createlist',
    ECOMMERCE_FARVORITES_DESTINATION_SORTBY: '#sortby',
    ECOMMERCE_FARVORITES_SORTBY_TEXT: 'Sort by',
    ECOMMERCE_FARVORITES_CREATE_TEXT: 'Create new list',
    ECOMMERCE_MODAL_JOIN_ACCOUNT: 'modal-joinAccount',
    ECOMMERCE_MODAL_VERIFICATION_FAILED: 'modal-verificationFailed',
    ECOMMERCE_MODAL_VERIFICATION_TIMEOUT: 'modal-verificationTimeout',
    ECOMMERCE_MODAL_BOTH_ACCOUNT: 'modal-bothTypesOfAccount',
    ECOMMERCE_MODAL_DRIVER_LICENSE: 'modal-driverLicense',
    ECOMMERCE_MODAL_VERIFY_ADDRESS: 'modal-verifyAddress',
    ECOMMERCE_INVENTORY_ALERT_VIEWED: 'inventory_alert_viewed',
    ECOMMERCE_UNAVAILABLE_FROM_PC: 'unavailable from pc',
    ECOMMERCE_CTA_CHANGE_STORE: 'Change store',
    ECOMMERCE_CTA_CHANGE_DATE: 'Change dates',
    ECOMMERCE_CTA_SELECTION: 'selection',
    ECOMMERCE_CTA_DATE_PICKER: '#datepicker',
    ECOMMERCE_CTA_STORE_PICKER: '#locationpicker',
    ECOMMERCE_TOGGLE_CHANGED: 'toggle_changed',
    ECOMMERCE_TOGGLE_NAME: 'availability',
    ECOMMERCE_VIEW_ITEM: 'view_item',
    ECOMMERCE_CONFIRM_CHANGED_DETAILS: 'modal - confirm changed details',
    ECOMMERCE_YES_CHANGE_DETAILS: 'Confirm',
    ECOMMERCE_NO_DONT_CHANGE_DETAILS: `Don't change details`,
    ECOMMERCE_BACK_LINK_TYPE: 'Back',
    ECOMMERCE_GLOBAL_USER_DATA_EVENT: 'user_data_provided',
    ECOMMERCE_ALERT: 'alert',
    ECOMMERCE_NO_MATCHING_ADDRESS_FOUND: 'no matching addresses found',
    ECOMMERCE_GLOBAL_USER_DATA_EVENT: 'user_data_provided',
    ECOMMERCE_EMAIL_VERIFY_FORM_STARTED: 'form_started',
    ECOMMERCE_EMAIL_VERIFY_FORM_SUBMITTED: 'form_submitted',
    ECOMMERCE_EMAIL_VERIFY_FORM_ERROR: 'form_error',
    ECOMMERCE_EMAIL_VERIFY_FORM_COMPLETED: 'form_completed',
    ECOMMERCE_EMAIL_VERIFY_FORM_NAME: 'guided_registration_auth',
    ECOMMERCE_EMAIL_VERIFY_FORM_ERROR_INCORRECT_OTP: 'Incorrect Verification Code',
    ECOMMERCE_EMAIL_VERIFY_FORM_ERROR_EMPTY_FIELD: 'Verification code is empty',
    ECOMMERCE_EMAIL_VERIFY_FORM_ERROR_TIMEOUT: 'Verification Timed out',
    ECOMMERCE_EMAIL_VERIFY_FORM_ERROR_TOO_MAANY_REQUESTS: 'Too many requests has been sent',
    ECOMMERCE_GUIDED_REGISTRATION_FORM_NAME: 'guided_registration_landing_page',
    ECOMMERCE_GUIDED_REGISTRATION_CONVERT_PROFILE: 'guided_registration_convert_profile',
    ECOMMERCE_FORM_STARTED: 'form_started',
    ECOMMERCE_FORM_COMPLETED: 'form_completed',
    LINK_TYPE_LOCATION_BODY: 'body',
    ECOMMERCE_CASH_APPLICATION_COMPLETED: 'cash_application_completed',
    ECOMMERCE_CASH_APPLICATION_ERROR: 'cash_application_error',
    ECOMMERCE_CREATE_CASH_ACCOUNT: 'create cash account',
    ECOMMERCE_TRUE: 'true',
    ECOMMERCE_MODAL_NO_DELIVERY_SLOTS: 'modal - no delivery slots',
    ECOMMERCE_MODAL: 'modal',
    ECOMMERCE_BUTTON: 'button',
    ECOMMERCE_CLOSE: 'close',
    ECOMMERCE_MODAL_COMPLETE_ADDRESS: 'modal - complete your address',
    ECOMMERCE_MODAL_SUGGEST_ADDRESS: 'modal - suggested address'
};

export const EVENT_ECOMMERCE_PAYLOAD_CONFIG = {
    ecommerce: {
        productClick: {
            v: '1.1',
            actionField: { list: string },
            products: array
        },
        detail: {
            v: '1.1',
            actionField: { list: string },
            products: array
        },
        add: {
            v: '1.1',
            actionField: { list: string },
            products: array
        },
        remove: {
            v: '1.1',
            actionField: { list: string },
            products: array
        },
        quantity: {
            v: '1.1',
            actionField: { list: string },
            products: array
        },
        checkout: {
            v: '1.1',
            actionField: { step: number },
            products: array
        },
        checkout_option: {
            v: '1.1',
            actionField: {
                step: number,
                option: object
            }
        },
        purchase: {
            currencyCode: string,
            purchase: {
                actionField: {
                    id: string,
                    revenue: string,
                    tax: number,
                    subtotal: number,
                    otherFees: number,
                    rentalProtectionPlan: number,
                    fuelConveniencePlan: number,
                    environmentalFee: number,
                    deliveryFee: number,
                    transportationSurcharge: number,
                    zipBill: string,
                    zipShip: string
                },
                products: array,
                payMethod: string,
                rentalMethod: string,
                reservationResult: string,
                rentalDuration: number,
                sunbeltLocation: string
            }
        },
        page: {
            type: string,
            currencyCode: string
        },
        updateVirtualPath: {
            virtualPageTitle: string,
            virtualPageURL: string
        }
    }
};

export const ECOMMERCE_PAYLOAD_FUNC = {
    payloadForEcommerceProductClick: (list, products, callback) => {
        let payload = {
            ...EVENT_ECOMMERCE_PAYLOAD_CONFIG.ecommerce.productClick,
            actionField: {
                list: list
            },
            products: products
        };
        callback(EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_PRODUCT_CLICK, payload);
    },
    payloadForEcommerceDetail: (list, products, availability, callback) => {
        let payload = {
            ...EVENT_ECOMMERCE_PAYLOAD_CONFIG.ecommerce.detail,
            actionField: {
                list: list
            },
            products: products,
            availability: availability
        };
        callback(EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_DETAIL, payload);
    },
    payloadForEcommerceAdd: (list, products, callback) => {
        let payload = {
            ...EVENT_ECOMMERCE_PAYLOAD_CONFIG.ecommerce.add,
            actionField: {
                list: list
            },
            products: products
        };
        callback(EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_ADD, payload);
    },
    payloadForEcommerceRemove: (list, products, callback) => {
        let payload = {
            ...EVENT_ECOMMERCE_PAYLOAD_CONFIG.ecommerce.remove,
            actionField: {
                list: list
            },
            products: products
        };
        callback(EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_REMOVE, payload);
    },
    payloadForEcommerceQuantity: (list, products, callback) => {
        let payload = {
            ...EVENT_ECOMMERCE_PAYLOAD_CONFIG.ecommerce.quantity,
            actionField: {
                list: list
            },
            products: products
        };
        callback(EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_QUANTITY, payload);
    },
    payloadForEcommerceCheckout: (step, products, callback) => {
        let payload = {
            ...EVENT_ECOMMERCE_PAYLOAD_CONFIG.ecommerce.checkout,
            actionField: {
                step: step
            },
            products: products
        };
        callback(EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_CHECKOUT, payload);
    },
    payloadForEcommerceCheckoutOption: (step, option, callback) => {
        let payload = {
            ...EVENT_ECOMMERCE_PAYLOAD_CONFIG.ecommerce.checkout_option,
            actionField: {
                step: step,
                option: option
            }
        };
        callback(EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_CHECKOUT_OPTION, payload);
    },
    payloadForEcommercePurchase: (
        currencyCode,
        id,
        revenue,
        tax,
        subtotal,
        otherFees,
        rentalProtectionPlan,
        fuelConveniencePlan,
        environmentalFee,
        deliveryFee,
        transportationSurcharge,
        zipBill,
        zipShip,
        payMethod,
        rentalMethod,
        reservationResult,
        rentalDuration,
        sunbeltLocation,
        products,
        callback
    ) => {
        let payload = {
            ...EVENT_ECOMMERCE_PAYLOAD_CONFIG.ecommerce.purchase,
            currencyCode: currencyCode,
            purchase: {
                actionField: {
                    id: id,
                    revenue: revenue,
                    tax: tax,
                    subtotal: subtotal,
                    otherFees: otherFees,
                    rentalProtectionPlan: rentalProtectionPlan,
                    fuelConveniencePlan: fuelConveniencePlan,
                    environmentalFee: environmentalFee,
                    deliveryFee: deliveryFee,
                    transportationSurcharge: transportationSurcharge,
                    zipBill: zipBill,
                    zipShip: zipShip
                },
                products: products,
                payMethod: payMethod,
                rentalMethod: rentalMethod,
                reservationResult: reservationResult,
                rentalDuration: rentalDuration,
                sunbeltLocation: sunbeltLocation
            }
        };
        callback(EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_PURCHASE, payload);
    },
    payloadForEcommercePage: (type, currencyCode, callback) => {
        let payload = {
            ...EVENT_ECOMMERCE_PAYLOAD_CONFIG.ecommerce.page,
            type: type,
            currencyCode: currencyCode
        };
        callback(EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_PAGE, payload);
    },
    payloadForUpdateVirtualPath: (virtualPageTitle, virtualPageURL, callback) => {
        let payload = {
            ...EVENT_ECOMMERCE_PAYLOAD_CONFIG.ecommerce.updateVirtualPath,
            virtualPageTitle: virtualPageTitle,
            virtualPageURL: virtualPageURL
        };
        callback(EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_UPDATE_VIRTUAL_URL, payload);
    }
};
